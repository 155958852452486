@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Font: Pretendard Variable (가변 다이나믹 서브셋) */
  @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css');
  body {
    font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
      Roboto, 'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic',
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    color: #1f1f1f;
  }

  /* Disable: Default arrow in <select> */
  select {
    -webkit-appearance: none;
    -o-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  /* Disable: Image drag or select  */
  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  input[type='checkbox']:checked {
    background-color: #0d2442;
    background-image: url('../src/assets/icons/checkmark.svg');
  }

  .filterCheckbox {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(158, 158, 158, 1);
    border-radius: 2px;

    &:checked {
      border-color: transparent;
      background-image: url("data:image/svg+xml,%3csvg viewBox='2.5 2.5 11 11' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
      background-size: 100% 100%;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: rgba(34, 34, 34, 1);
    }
  }
}

@layer components {
  .btn-primary_large {
    @apply w-[400px] h-[52px] py-[15px] bg-primary text-text16 text-white text-center select-none cursor-pointer;
  }

  .btn-primary_small {
    @apply flex items-center justify-center text-white cursor-pointer select-none text-text14 bg-primary;
  }

  .btn-secondary_small {
    @apply flex items-center justify-center bg-white border cursor-pointer select-none text-text14 text-text border-border;
  }

  .sidebar-tab {
    @apply w-full px-20 py-[14px] bg-white border-y-2 border-y-gray text-text16 text-text flex justify-between cursor-pointer;
  }

  .sidebar-subtab_selected {
    @apply w-full px-40 py-[14px] bg-gray border-l-[3px] border-l-primary text-text14 text-primary cursor-pointer;
  }

  .sidebar-subtab {
    @apply w-full px-40 py-[14px] bg-white text-text14 text-text-strong cursor-pointer;
  }
}
